.memberInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 24px;

  .header {
    display: flex;
    width: 50%;
    justify-content: space-between;

    svg {
      padding: 12px 24px;
      font-size: 24px;
    }
  }

  .subTitle {
    display: flex;
    width: 100%;
    justify-content: space-between;

    span {
      padding: 12px 24px;
    }
  }

  .newRegSubTitle {
    display: flex;
    width: 50%;
    justify-content: space-between;

    span {
      padding: 12px 24px;
    }
  }

  .memberList {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 20px;

    .memberCard {
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 24px;
    }

    .footer {
      display: flex;
      justify-content: center;
      position: absolute;
      gap: 24px;
      width: 100%;
      left: 0;
      bottom: 5%;
    }
  }

  .filter {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .applyStatus,
    .applyDate {
      display: flex;

      .filterType {
        flex: 1;
      }

      .filterBox {
        flex: 4;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 15px;
        gap: 20px;

        select {
          width: 30%;
          height: 30px;
          margin-left: 3px;
        }
      }
    }
  }
}
