.popUp {
  display: flex;
  justify-content: space-between;

  .info {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    .edit {
      display: flex;
      flex-direction: column;

      .subTitle {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        font-size: 24px;
        font-weight: bold;
      }

      .block {
        padding: 12px;
        border: 1px solid;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        cursor: pointer;

        // svg {
        //   font-size: 24px;
        // }

        // span {
        //   width: 90%;
        //   flex-wrap: wrap;
        // }

        // input {
        //   width: 90%;
        //   flex-wrap: wrap;
        //   font-size: 16px;
        //   border: 0px;
        // }

        // .button {
        //   display: flex;
        //   gap: 12px;
        // }
      }

      .deactivate {
        padding: 12px;
        border: 1px solid #eee;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        cursor: pointer;
      }

      input {
        width: 500px;
        font-size: 16px;
        padding: 12px;
      }

      .experience {
        display: flex;
        flex-direction: column;
        gap: 24px;
        font-size: 18px;

        input {
          margin-top: 6px;
        }

        .button {
          button {
            font-size: 16px;
            font-weight: bold;
            border: 1.5px solid #ffffff;
            border-radius: 10px;
            width: 170px;
            height: 50px;
            padding: 12px;
          }
        }

        .active {
          button {
            font-size: 16px;
            font-weight: bold;
            border: 1.5px solid #ffffff;
            border-radius: 10px;
            width: 170px;
            height: 50px;
            padding: 12px;
            background-color: #ad4deb;
            color: white;
          }
        }

        .select {
          display: flex;

          .selectTitle {
            flex: 20%;
            display: flex;
            align-items: center;
          }

          .selectDate {
            flex: 80%;
            display: flex;
            gap: 24px;

            select {
              font-size: 16px;
              border: 1.5px solid #c4c4c4;
              border-radius: 10px;
              width: 150px;
              height: 50px;
            }
          }
        }
      }

      .service {
        p {
          font-size: 18px;
          font-weight: bold;
        }

        select {
          width: 200px;
          height: 45px;
        }
      }

      .two {
        display: flex;
        gap: 24px;
      }

      input[type="checkbox"] {
        appearance: none;
        background-color: #fafafa;
        border: 1px solid #cacece;
        padding: 9px;
        border-radius: 3px;
        display: inline-block;
        position: relative;
        width: 70px;
        height: 30px;
      }

      input[type="checkbox"]:checked {
        background-color: #626268;
        border: 1px solid #626268;
        color: #626268;
      }

      input[type="checkbox"]:checked:after {
        font-size: 14px;
        position: absolute;
        top: 0px;
        left: 3px;
        color: #626268;
      }
    }
  }

  .button {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 24px;

    svg {
      font-size: 24px;
    }
  }
}
