.notice {
  display: flex;
  flex-direction: column;
  gap: 48px;

  .edit {
    display: flex;
    flex-direction: column;

    .subTitle {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      font-size: 24px;
      font-weight: bold;
    }

    .block {
      padding: 36px;
      border: 1px solid #eee;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1028px;

      svg {
        font-size: 24px;
      }

      span {
        width: 90%;
        flex-wrap: wrap;
      }

      input {
        width: 90%;
        flex-wrap: wrap;
        font-size: 16px;
        border: 0px;
      }

      .button {
        display: flex;
        gap: 12px;
      }
    }
  }
}
