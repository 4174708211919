.memberDetail {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;

  .subTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 24px;
    font-weight: bold;
  }

  .block {
    padding: 24px;
    border: 1px solid #eee;

    .subTitle {
      margin-bottom: 24px;
    }

    .section {
      display: flex;
      flex-direction: column;
      border: 1px solid #eee;

      .item {
        display: flex;
        justify-content: space-between;
        padding: 12px 24px;

        &:not(:last-child) {
          border-bottom: 1px solid #eee;
        }
      }
    }

    .aim {
      display: flex;
      gap: 24px;

      .payment {
        border: 1px solid #eee;
        width: 100%;
        display: flex;
        padding: 12px 18px;
        justify-content: space-between;
      }
    }

    .button {
      display: flex;
      border: 1px solid #eee;
    }
  }

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    svg {
      padding: 0 12px;
      font-size: 24px;
    }
  }

  .total {
    display: flex;
    gap: 48px;

    .half {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 24px;

      .target {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .aim {
          display: flex;
          gap: 24px;

          span {
            border: 1px solid #eee;
            padding: 12px 24px;
          }
        }
      }
    }
  }
}
