.landing {
  display: flex;
  justify-content: center;
  gap: 24px;
  height: 700px;

  .service {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    padding: 24px;

    .card {
      border: 1px solid #cecece;
      width: 360px;
      height: 300px;
      display: flex;
      flex-direction: column;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }

      .icon {
        display: flex;
        position: relative;
        font-size: 48px;
      }
    }
  }
}

@media screen and (max-width: 430px) {
  .landing {
    .service {
      display: flex;
      flex-direction: column;
    }
  }
}
