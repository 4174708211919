.MentorTimetable {
  width: 950px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .top {
    display: flex;

    .side {
      flex: 0 40%;
      border-right: 1px solid #eee;

      .box {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 24px 24px 24px 0;

        .bottom {
          display: flex;
          justify-content: center;
          gap: 48px;

          svg {
            font-size: 24px;
          }
        }
      }

      .button {
        display: flex;
        justify-content: center;

        padding: 8px 24px;
        color: #333;
        background: #eee;

        cursor: pointer;

        &:hover {
          opacity: 0.75;
        }
      }

      .list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        height: 215px;
        overflow-y: scroll;

        .item {
          display: flex;
          justify-content: space-between;
          gap: 24px;
          padding: 16px 24px 16px 0;
          border-bottom: 1px solid #eee;

          main {
            display: flex;
            gap: 48px;
          }

          &:first-child {
            border-top: 1px solid #eee;
          }

          i {
            padding: 6px;
            font-size: 18px;
            cursor: pointer;
          }

          .time {
            display: flex;
            flex-direction: column;
            gap: 8px;
          }
        }
      }
    }

    .main {
      flex: 0 60%;
      display: flex;
      flex-direction: column;
      gap: 12px;

      padding: 0 24px;
      height: 504px;
      overflow-y: scroll;

      .list {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .item {
          display: flex;
          justify-content: space-between;
          gap: 24px;
          padding: 12px 0;
          border-bottom: 1px solid #eee;

          .weekday {
            flex: 0.5;
            align-self: flex-start;
            display: flex;
            align-items: center;
            gap: 16px;

            input {
              cursor: pointer;
            }

            label {
              padding-right: 48px;
              cursor: pointer;
            }
          }

          .timeBox {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 16px;
          }

          .addBtn {
            padding: 8px;
          }

          i {
            font-size: 18px;
            cursor: pointer;
          }
        }
      }
    }

    .subTitle {
      font-weight: bold;
    }

    .des {
      color: #999;
    }

    .timeBoxItem {
      display: flex;
      flex-direction: column;
      gap: 8px;

      header {
        display: flex;
        align-items: center;
        gap: 16px;

        .timeInput {
          padding: 4px 8px;
          border: 1px solid #eee;

          select {
            padding: 4px 8px;
            border: 0;
            outline: 0;
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    font-size: 20px;
  }
}
