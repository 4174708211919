.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;

  form {
    display: flex;
    flex-direction: column;
    width: 20%;
    margin-top: 24px;
    font-size: 24px;

    input {
      margin-bottom: 24px;
      font-size: 18px;
    }

    button {
      margin-top: 24px;
      font-size: 18px;
    }
  }
}
