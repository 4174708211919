.mentorDetail {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;

  .block {
    padding: 24px 24px 12px 24px;
    border: 1px solid #eee;

    .edit {
      font-size: 24px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .subTitle {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 8px;
      font-size: 24px;
      font-weight: bold;
    }

    .serviceCardList {
      display: flex;
      flex-direction: column;
      gap: 24px;
      margin-top: 16px;

      .serviceCard {
        font-size: 18px;
        display: flex;
        gap: 8px;
        padding: 24px;
        border: 1px solid #eee;

        .detail {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 8px;

          header {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 12px;
            font-weight: bold;

            div {
              display: flex;
              justify-content: space-between;

              .price {
                display: flex;
                align-items: center;
                gap: 8px;

                small {
                  text-decoration: line-through;
                }
              }
            }

            small {
              color: #666;
            }
          }
        }

        .icon {
          display: flex;
          justify-content: flex-end;

          svg {
            font-size: 24px;
          }
        }
      }
    }

    // .bookingHeader {
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   padding: 16px 0;

    //   i {
    //     font-size: 24px;
    //   }
    // }

    // .bookingDateList {
    //   display: flex;

    //   ul {
    //     flex: 1;
    //     border: 1px solid #eee;
    //     border-left: none;

    //     &:first-child {
    //       border-left: 1px solid #eee;
    //     }

    //     li {
    //       display: flex;
    //       flex-direction: column;
    //       align-items: center;
    //       align-content: stretch;
    //       padding: 12px;

    //       &:first-child {
    //         border-bottom: 1px solid #eee;
    //       }

    //       &:last-child {
    //         height: 200px;
    //         gap: 12px;
    //       }

    //       span {
    //         &:last-child {
    //           font-size: 24px;
    //           font-weight: bold;
    //         }
    //       }
    //     }
    //   }
    // }

    .expList {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-top: 16px;

      .item {
        display: flex;
        justify-content: space-between;
        padding-bottom: 12px;

        .name {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 8px;

          .title {
            font-weight: bold;
          }
        }

        .time {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          gap: 24px;

          svg {
            font-size: 24px;
          }
        }

        &:not(:last-child) {
          border-bottom: 1px solid #eee;
        }
      }
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      em {
        font-size: 24px;
      }
    }

    .permission {
      display: flex;
      justify-content: space-around;

      div {
        border: 1px #a9a9a966 solid;
        margin-top: 12px;
        padding: 12px 48px;
      }
    }
  }

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    svg {
      padding: 0 12px;
      font-size: 24px;
    }
  }

  .total {
    display: flex;
    gap: 48px;

    .left {
      flex: 3;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .mentorSkillList {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;

        .mentorSkillCard {
          header {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 18px;
            font-weight: bold;

            span {
              color: #666;
            }
          }
        }
      }
    }

    .right {
      flex: 2;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .mentorTimetable {
        .timetable {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .title {
            font-weight: bold;
          }

          .des {
            color: #999999;
          }

          .available,
          .overwrite {
            display: flex;
            flex-direction: column;
            width: 100%;
            font-size: 16px;
            border: 1px solid #eee;

            .item {
              display: flex;
              justify-content: space-between;
              gap: 24px;
              padding: 12px 24px;

              .weekday {
                flex: 1 10%;
              }

              &:not(:last-child) {
                border-bottom: 1px solid #eee;
              }

              .timeBox {
                .timeBoxItem {
                  header {
                    display: flex;
                    gap: 4px;
                  }
                }
                .bankName {
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  max-width: 250px;
                }
              }
            }

            .list {
              height: calc(45px * 4 - 45px / 2);
              overflow-y: scroll;

              .item {
                display: flex;
                flex-direction: column;

                main {
                  display: flex;
                  justify-content: space-between;
                }
              }
            }
          }
        }
      }
    }
  }
}
